/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

/* Container and layout */
.article-container {
  padding: 2rem;
  font-family: 'Open Sans', sans-serif;
}

.article-header {
  padding-bottom: 0;
}

.article-image-wrapper {
  width: 100%;
  margin-bottom: 1rem;
}

/* Image styling */
.article-image {
  max-height: 500px;
  width: 100%;
  object-fit: cover;
}

/* Typography and colors */
.article-title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-family: 'Open Sans', sans-serif;
}

.article-content {
  line-height: 1.6;
}

.article-subtitle,
.article-date {
  font-size: 1.2rem;
  margin-top: 0.5rem;
  color: #000000;
}

.article-date {
  font-size: 1rem;
}

/* Header hierarchy within content */
.article-content h1,
.article-content h2,
.article-content h3,
.article-content h4,
.article-content h5,
.article-content h6 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

/* Paragraphs and blockquotes */
.article-content p {
  margin-bottom: 1.5rem;
}

.article-content blockquote {
  font-style: italic;
  margin: 1.5rem 0;
  padding: 0.5rem 1rem;
  background-color: #f8f9fa;
  border-left: 4px solid #007bff;
}

.article-content .first-paragraph {
  margin-top: 0.5rem;
}

/* Media queries for responsive adjustments */
/* Media queries for responsive adjustments */
@media only screen and (max-width: 768px) {
  .article-container {
    padding: 1rem;
    font-size: 0.9em; /* Adjust font size for mobile */
  }

  .article-content {
    font-size: 0.9em;
  }
  
  .article-image-wrapper {
    margin-bottom: 0.5rem;
  }
  
  .article-image {
    max-height: 300px;
  }
}

@keyframes shimmer {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

.skeleton {
  animation: shimmer 1.5s infinite ease-in-out;
  background: #ddd;
  border-radius: 4px;
  margin: 4px 0;
  height: 20px;
}

.skeleton-title {
  width: 60%;
  height: 2rem;
}

.skeleton-date {
  width: 40%;
  height: 1rem;
}

.skeleton-img {
  width: 100%;
  height: 300px;
}

.skeleton-text {
  width: 90%;
  height: 10px;
  margin: 8px 0;
}

.title-and-sharing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-sharing {
  display: flex;
  gap: 10px;
}

.social-sharing button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  color: #555; /* Color can be adjusted based on the design */
}

.social-sharing button:hover,
.social-sharing button:focus {
  color: #007bff; /* Change hover color */
}