/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

/* Base styles for articles */
.article-title {
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
}

.article-summary {
  font-size: 1rem;
  display: none;
}

/* Base styles for images */
.card-img-top,
.latest-article-image {
  max-width: 100%;
  object-fit: cover;
}

.card-img-top {
  max-height: 250px;
}

.latest-article-image {
  max-height: 400px;
}

/* Base styles for titles */
.main-title {
  display: block;
  font-size: 1.5rem;
}

.sub-title {
  display: block;
  font-size: 0.9rem;
}

/* Styles for latest article */
.latest-article-title {
  font-size: 10rem;
  text-shadow: 1px 1px 0 #000, -2px -2px 0 #000, 2px -2px 0 #1e1d1d, -2px 2px 0 #1b1a1a;
}

.latest-article-image:hover {
  opacity: 1;
}

/* Overlay styles */
.overlay-top {
  align-self: start;
  padding: 1rem;
}

.overlay-bottom {
  align-self: end;
  padding: 1rem;
  text-align: right;
}

/* Media Queries */
@media (max-width: 575px) {
  .latest-article-title {
    font-size: 2rem; /* Smaller size for mobile screens */
    font-family: 'Open Sans', sans-serif;
  }
}

@media (min-width: 576px) {
  .article-title {
    font-size: 1.5rem;
  }

  .article-summary,
  .latest-article-summary {
    display: block;
    font-size: 1.2rem;
  }

  .latest-article-title {
    font-size: 3rem;
  }

  .main-title {
    font-size: 1.8rem;
  }

  .sub-title {
    font-size: 1.2rem;
  }
}
